<template>
    <section class="section">
        <h2>出品禁止ASIN設定</h2>

        <h4>出品禁止ASIN一括追加</h4>
        <div class="row mb-5">
            <form class="col-md-8" @submit.prevent="save">
                <div class="mb-3">
                    <p>追加するASINを1行に1つずつ (改行区切りで) 入力してください。</p>
                    <textarea v-model="asins_to_add" rows="10" class="form-control" required></textarea>
                </div>
                <div>
                    <button class="btn btn-primary">追加する</button>
                </div>
            </form>
        </div>

        <h4>出品禁止中のASIN</h4>

        <inline-loader v-if="loading"></inline-loader>
        <div class="row" v-else>
            <div class="col-8">
                <div class="mb-3">
                    <button class="btn btn-outline-danger" @click="remove()">選択したものの出品禁止を解除</button>
                </div>
                <div class="list-group mb-3">
                    <label v-for="forbidden_asin of forbidden_asins" :key="forbidden_asin.forbidden_asin_id" class="list-group-item">
                        <input type="checkbox" :value="forbidden_asin.forbidden_asin_id" v-model="asins_to_delete">
                        {{ forbidden_asin.asin }}
                    </label>
                </div>
                <div class="mb-3">
                    <button class="btn btn-outline-danger" @click="remove()">選択したものの出品禁止を解除</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InlineLoader from '@/shared/components/tool/InlineLoader';

export default {
    name: 'PageSettingForbiddenAsin',
    components: {
        InlineLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: false,
            forbidden_asins: [],
            asins_to_add: null,
            asins_to_delete: [],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loading = true;
            this.$http.get('/forbidden-asins')
            .then((response) => {
                this.forbidden_asins = [];
                for (let row of response.data) {
                    this.forbidden_asins.push(row);
                }
            })
            .finally(() => {
                this.loading = false;
            });
        },
        save() {
            this.startScreenLoading();
            this.$http.post('/forbidden-asins/bulk-insert', {asins_joined: this.asins_to_add})
            .then((response) => {
                this.forbidden_asins = [];
                for (let row of response.data) {
                    this.forbidden_asins.push(row);
                }
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        remove() {
            this.startScreenLoading();
            this.$http.post('/forbidden-asins/bulk-delete', {forbidden_asin_ids: this.asins_to_delete})
            .then((response) => {
                this.forbidden_asins = [];
                for (let row of response.data) {
                    this.forbidden_asins.push(row);
                }
                this.showMessage('解除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
